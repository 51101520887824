export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 24.39,
    prev_app_rate: 74.79,
    current_avg_round: 3198,
    prev_avg_round: 3390
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 1.28,
    prev_app_rate: 0.72,
    current_avg_round: 3254,
    prev_avg_round: 3334
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 2.01,
    prev_app_rate: 2.19,
    current_avg_round: 3316,
    prev_avg_round: 3326
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 40.38,
    prev_app_rate: 35.87,
    current_avg_round: 3358,
    prev_avg_round: 3374
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 1.17,
    prev_app_rate: 2.39,
    current_avg_round: 3300,
    prev_avg_round: 3314
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 7.91,
    prev_app_rate: 28.1,
    current_avg_round: 3141,
    prev_avg_round: 3309
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 3.58,
    prev_app_rate: 0.54,
    current_avg_round: 3137,
    prev_avg_round: 3119
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 10.79,
    prev_app_rate: 12.73,
    current_avg_round: 3462,
    prev_avg_round: 3452
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 16.84,
    prev_app_rate: 13.56,
    current_avg_round: 3302,
    prev_avg_round: 3332
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 8.87,
    prev_app_rate: 1.71,
    current_avg_round: 3157,
    prev_avg_round: 3222
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 31.01,
    prev_app_rate: 12.65,
    current_avg_round: 3343,
    prev_avg_round: 3351
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.01,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 77.26,
    prev_app_rate: 58.49,
    current_avg_round: 3640,
    prev_avg_round: 3524
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 31.33,
    prev_app_rate: 56.75,
    current_avg_round: 3249,
    prev_avg_round: 3370
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 81.92,
    prev_app_rate: 65.14,
    current_avg_round: 3613,
    prev_avg_round: 3505
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 1.89,
    prev_app_rate: 4.35,
    current_avg_round: 3337,
    prev_avg_round: 3417
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 1.39,
    prev_app_rate: 3.72,
    current_avg_round: 3170,
    prev_avg_round: 3294
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 1.07,
    prev_app_rate: 0.51,
    current_avg_round: 3262,
    prev_avg_round: 3250
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 0.13,
    prev_app_rate: 0.13,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 3.9,
    prev_app_rate: 6.34,
    current_avg_round: 3379,
    prev_avg_round: 3356
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.02,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 25.19,
    prev_app_rate: 16.91,
    current_avg_round: 3318,
    prev_avg_round: 3320
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 87.48,
    prev_app_rate: 77.45,
    current_avg_round: 3616,
    prev_avg_round: 3486
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 10.32,
    prev_app_rate: 8.86,
    current_avg_round: 3164,
    prev_avg_round: 3266
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 1.44,
    prev_app_rate: 0,
    current_avg_round: 3184,
    prev_avg_round: 0
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 0.07,
    prev_app_rate: 2.22,
    current_avg_round: 0,
    prev_avg_round: 3161
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 7.56,
    prev_app_rate: 5.05,
    current_avg_round: 3261,
    prev_avg_round: 3236
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 4.4,
    prev_app_rate: 26.21,
    current_avg_round: 3147,
    prev_avg_round: 3305
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.29,
    prev_app_rate: 0.21,
    current_avg_round: 3285,
    prev_avg_round: 0
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 13.18,
    prev_app_rate: 14.82,
    current_avg_round: 3268,
    prev_avg_round: 3329
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 2.2,
    prev_app_rate: 1.47,
    current_avg_round: 3249,
    prev_avg_round: 3288
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.11,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - Hunt',
    rarity: '4*',
    current_app_rate: 14.1,
    prev_app_rate: 0,
    current_avg_round: 3308,
    prev_avg_round: 0
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.01,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.21,
    prev_app_rate: 0.29,
    current_avg_round: 3271,
    prev_avg_round: 3281
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 20.67,
    prev_app_rate: 37.74,
    current_avg_round: 3252,
    prev_avg_round: 3419
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.56,
    prev_app_rate: 1.57,
    current_avg_round: 3134,
    prev_avg_round: 3241
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 44.58,
    prev_app_rate: 18.39,
    current_avg_round: 3385,
    prev_avg_round: 3333
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 94.21,
    prev_app_rate: 89.31,
    current_avg_round: 3608,
    prev_avg_round: 3475
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.15,
    prev_app_rate: 0.57,
    current_avg_round: 0,
    prev_avg_round: 3224
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 1.4,
    prev_app_rate: 6.02,
    current_avg_round: 3055,
    prev_avg_round: 3282
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.1,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 21.21,
    prev_app_rate: 50.49,
    current_avg_round: 3224,
    prev_avg_round: 3421
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 33.48,
    prev_app_rate: 36.32,
    current_avg_round: 3235,
    prev_avg_round: 3273
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.62,
    prev_app_rate: 0.6,
    current_avg_round: 3337,
    prev_avg_round: 3398
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 18.43,
    prev_app_rate: 8.29,
    current_avg_round: 3299,
    prev_avg_round: 3219
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 30.4,
    prev_app_rate: 13.12,
    current_avg_round: 3377,
    prev_avg_round: 3339
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.47,
    prev_app_rate: 1.09,
    current_avg_round: 3102,
    prev_avg_round: 3282
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.11,
    prev_app_rate: 1.82,
    current_avg_round: 0,
    prev_avg_round: 3365
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.07,
    prev_app_rate: 0.01,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.39,
    prev_app_rate: 0.13,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 19.51,
    prev_app_rate: 0,
    current_avg_round: 3369,
    prev_avg_round: 0
  }
];
